// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-bilder-js": () => import("./../../../src/pages/bilder.js" /* webpackChunkName: "component---src-pages-bilder-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-digikompetenzpodcast-js": () => import("./../../../src/pages/digikompetenzpodcast.js" /* webpackChunkName: "component---src-pages-digikompetenzpodcast-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kursanmeldung-erfolgreich-js": () => import("./../../../src/pages/kursanmeldung/erfolgreich.js" /* webpackChunkName: "component---src-pages-kursanmeldung-erfolgreich-js" */),
  "component---src-pages-spritzgiessen-js": () => import("./../../../src/pages/spritzgiessen.js" /* webpackChunkName: "component---src-pages-spritzgiessen-js" */),
  "component---src-pages-vcard-helmutkraft-js": () => import("./../../../src/pages/vcard/helmutkraft.js" /* webpackChunkName: "component---src-pages-vcard-helmutkraft-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/Course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/Podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */)
}

